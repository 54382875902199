import { useState } from "react";
import { Weapon } from "../generator/types";
import Utweapons from "./weapons.json";
import seedrandom from "seedrandom";
import Fuse from "fuse.js";
import { cn } from "@/lib/utils";
import type { FuseResult } from "fuse.js";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import {
  compareKnockback,
  compareUseTime,
  RarityToInt,
  toCamelCase,
} from "@/utils";
import { Plus } from "lucide-react";
import { SiTwitter, SiReddit } from "@icons-pack/react-simple-icons";
import isMobile from "is-mobile";
import SaveManager from "./saveManager";
import { config } from "./config";
import Copyright from "@/components/copyright";
import "@/rarity.css";
import logo from "@/assets/title.png";

const weapons = Utweapons as Weapon[];
const save = new SaveManager();

function App() {
  const tableClasses =
    "border-solid border-black border-2 text-center align-center center-items";
  const fuseOptions = {
    includeScore: true,
  };
  const tomorrow = (config.currentDay + 1) * 1000 * 60 * 60 * 24;
  const rng = seedrandom(
    Math.floor(config.currentDay).toString() + config.seed,
  );
  const lastrgn = seedrandom(
    Math.floor(config.currentDay - 1).toString() + config.seed,
  );
  const tdyWeapon = Math.floor(rng() * weapons.length);
  const weapon = weapons[tdyWeapon] as Weapon;
  // console.log(weapon);

  const lastWeapon = weapons[Math.floor(lastrgn() * weapons.length)] as Weapon;
  DEBUG && console.log(weapon);
  const weaponNames = weapons.map((weapon) => {
    return weapon.name;
  });
  const fuse = new Fuse(weaponNames, fuseOptions);

  const [search, setSearch] = useState<FuseResult<string>[]>([]);
  const [guesses, setGuesses] = useState<number[]>(save.getToday());
  const [completed, setCompleted] = useState(false);

  guesses.forEach((guess) => {
    if (guess == tdyWeapon && !completed) {
      setCompleted(true);
    }
  });

  function updateTime() {
    if (completed) {
      const diff = tomorrow - Date.now();
      const hours = Math.floor(diff / 1000 / 60 / 60);
      const minutes = Math.floor(diff / 1000 / 60) % 60;
      const seconds = Math.floor(diff / 1000) % 60;
      const timeUntil = `${hours}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} Until Reset`;
      // @ts-expect-error Element exists
      document.getElementById("time").innerHTML = timeUntil;
    }
  }
  setInterval(updateTime, 1000);

  function handleGuess(e: React.MouseEvent<HTMLDivElement>) {
    if (completed) {
      return;
    }
    const target = e.target as HTMLDivElement;
    const id = parseInt(target.getAttribute("data-id") || "0");
    if (!guesses.includes(id)) {
      setGuesses([...guesses, id]);
      save.addGuess(id);
      if (weapons[id].name == weapon.name) {
        setCompleted(true);
        alert(
          `You Successfully guessed the weapon in ${guesses.length + 1} tr${
            guesses.length > 0 ? "ies" : "y"
          }`,
        );
      }
      if (weapons[id].damage == null || isNaN(weapons[id].damage)) {
        throw new Error(
          `Damage is not a number id:${id}, name:${weapons[id].name}`,
        );
      }
    }
  }

  let result = `Calamidle day ${
    Math.floor(Date.now() / 1000 / 60 / 60 / 24) - config.dayOffest
  }\n${guesses.length} Guess${guesses.length > 1 ? "es" : ""}\n${guesses
    .map((guess) => {
      const first =
        weapons[guess].name == weapon.name
          ? config.correct.emoji
          : config.incorrect.emoji;
      const second =
        weapons[guess].damageType == weapon.damageType
          ? config.correct.emoji
          : config.incorrect.emoji;
      const third =
        weapons[guess].damage == weapon.damage
          ? config.correct.emoji
          : config.incorrect.emoji;
      const fourth =
        weapons[guess].knockbackString == weapon.knockbackString
          ? config.correct.emoji
          : config.incorrect.emoji;
      const fifth =
        weapons[guess].useTimeString == weapon.useTimeString
          ? config.correct.emoji
          : config.incorrect.emoji;
      const sixth =
        weapons[guess].rarity == weapon.rarity
          ? config.correct.emoji
          : config.incorrect.emoji;
      const seventh =
        weapons[guess].obtained == weapon.obtained
          ? config.correct.emoji
          : config.incorrect.emoji;

      return first + second + third + fourth + fifth + sixth + seventh;
    })
    .join("\n")}`;
  const bgCorrect = config.correct.bgcolor;
  const bgWrong = config.incorrect.bgcolor;
  return (
    <main className="flex min-h-screen flex-col items-center gap-15 p-5">
      <img src={logo} width={Math.min(500, window.innerWidth - 50)} />
      <p className="text-xl">How To play</p>
      <ol className="pb-5 list-inside list-decimal">
        <li>Type the name of a weapon in the search bar.</li>
        <li>Click on the weapon you are searching for.</li>
        <li>
          When you click on the weapon it will appear in the guesses area. There
          <br />
          will be feed back like is the damage correct or is it lower or higher
        </li>
        <li>
          at 7 wrong guesses you can get a hint in the form of the weapons
          <br />
          tooltip.
        </li>
      </ol>
      <div className="z-10 w-full max-w-5xl items-center justify-between text-sm lg:flex gap-5 flex-col flex">
        <form>
          <Input
            placeholder="Search for a weapon"
            id="search"
            type="text"
            auto-complete="off"
            onChange={(e) => {
              const parentel = e.target as HTMLInputElement;
              const value = parentel.value;
              const result = fuse.search(value).splice(0, 5);
              setSearch(result);
            }}
          />
        </form>
        {search.length > 0 && (
          <div className="flex flex-col gap-2 items-left  p-2 rounded-lg">
            {search.map((result) => {
              return (
                <div
                  onClick={handleGuess}
                  key={result.refIndex}
                  data-id={result.refIndex}
                  data-accuracy={result.score}
                  className="flex flex-row gap-5 bg-primary cursor-pointer rounded-lg p-2 hover:scale-95 hover:bg-muted"
                >
                  <img
                    data-id={result.refIndex}
                    src={weapons[result.refIndex].image}
                    className="max-w-20 max-h-20"
                  />
                  <p data-id={result.refIndex}>{result.item}</p>
                  <p data-id={result.refIndex}>{result.score}</p>
                </div>
              );
            })}
          </div>
        )}
        <div className="flex-col flex">
          {guesses.length >= 7 ? (
            <AlertDialog>
              <AlertDialogTrigger>
                <Button>Reveal Tooltip</Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Tooltip</AlertDialogTitle>
                  <AlertDialogDescription>
                    {weapon.tooltip.replace(
                      /\b(\w{2})\w*/g,
                      (match, group) => group + "-".repeat(match.length - 2),
                    )}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Close</AlertDialogCancel>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          ) : (
            <Button variant="secondary" className="cursor-not-allowed">
              Reveal Tooltip ({7 - guesses.length} left)
            </Button>
          )}
        </div>
        {completed && (
          <div className="text-center">
            Completed
            <br />
            <div id="time"></div>
            <div className="text-left">
              <pre>
                <code>{result}</code>
              </pre>
            </div>
            <div>
              <Drawer>
                <DrawerTrigger>
                  <Button>
                    <Plus className="mr-2 h-4 w-4" />
                    Share
                  </Button>
                </DrawerTrigger>
                <DrawerContent>
                  <DrawerHeader>
                    <DrawerTitle>Share</DrawerTitle>
                    <DrawerDescription>
                      <p>Share the result with your friends</p>
                    </DrawerDescription>
                  </DrawerHeader>
                  <DrawerFooter>
                    <div className="flex-row flex gap-4">
                      {isMobile() ? (
                        <Button
                          onClick={() => {
                            navigator.share({
                              url: "https://calamidle.pk3.zip",
                              title: "Calamidle",
                              text: result,
                            });
                          }}
                        >
                          Share
                        </Button>
                      ) : (
                        ""
                      )}
                      <Button>
                        {/* @ts-expect-error This works even tho error */}
                        <SiTwitter size={14} className="mr-2" />
                        <a
                          href={`https://twitter.com/intent/tweet?${new URLSearchParams(
                            { text: result },
                          ).toString()}`}
                          target="_blank"
                        >
                          Twitter
                        </a>
                      </Button>
                      <Button>
                        {/* @ts-expect-error This works even tho error */}
                        <SiReddit size={14} className="mr-2" />
                        <a
                          href={`https://www.reddit.com/submit?${new URLSearchParams(
                            { text: result, title: result.split("\n")[0] },
                          ).toString()}`}
                          target="_blank"
                        >
                          Reddit
                        </a>
                      </Button>
                    </div>
                    <DrawerClose>Close</DrawerClose>
                  </DrawerFooter>
                </DrawerContent>
              </Drawer>
            </div>
          </div>
        )}
        <div className="flex flex-col w-30 items-center justify-between ">
          <p>Yesterdays weapon</p>
          <img src={lastWeapon.image} className="w-15" />
          <p>{lastWeapon.name}</p>
        </div>
        <table className="w-full">
          <thead>
            <tr>
              <td className={tableClasses}>Weapon</td>
              <td className={tableClasses}>Damage Type</td>
              <td className={tableClasses}>Damage</td>
              <td className={tableClasses}>Knockback</td>
              <td className={tableClasses}>Speed</td>
              <td className={tableClasses}>Rarity</td>
              <td className={tableClasses}>Obtained</td>
            </tr>
          </thead>
          <tbody>
            {guesses.map((guess) => {
              const guessData = weapons[guess] as Weapon;
              const compareUseTimeR = compareUseTime(
                guessData.useTimeString,
                weapon.useTimeString,
              );
              const compareKnockbackR = compareKnockback(
                guessData.knockbackString,
                weapon.knockbackString,
              );
              const knockback = compareKnockbackR.matching
                ? bgCorrect
                : bgWrong;
              const knockbackText = compareKnockbackR.matching
                ? ""
                : compareKnockbackR.u
                  ? "↑"
                  : "↓";
              const useTime = compareUseTimeR.matching ? bgCorrect : bgWrong;
              const useTimeText = compareUseTimeR.matching
                ? ""
                : compareUseTimeR.u
                  ? "↑"
                  : "↓";
              const rarityText =
                RarityToInt(weapon.rarity) == RarityToInt(guessData.rarity)
                  ? ""
                  : RarityToInt(weapon.rarity) > RarityToInt(guessData.rarity)
                    ? "↑"
                    : "↓";
              let damage = "";
              let damageText = "";
              if (guessData.damage > weapon.damage) {
                damage = bgWrong;
                damageText = "↓";
              } else if (guessData.damage < weapon.damage) {
                damage = bgWrong;
                damageText = "↑";
              } else {
                damage = bgCorrect;
              }

              return (
                <tr className="h-20" key={guess}>
                  <td
                    align="center"
                    className={cn(
                      tableClasses,
                      guessData.name == weapon.name ? bgCorrect : bgWrong,
                    )}
                  >
                    <div className="w-min">
                      <img
                        src={guessData.image}
                        alt={guessData.name}
                        className="max-w-20 max-h-20"
                      />
                    </div>
                  </td>
                  <td
                    className={cn(
                      tableClasses,
                      guessData.damageType == weapon.damageType
                        ? bgCorrect
                        : bgWrong,
                    )}
                  >
                    {guessData.damageType}
                  </td>
                  <td className={cn(tableClasses, damage)}>
                    {guessData.damage || "?"}&nbsp;{damageText}
                  </td>
                  <td className={cn(tableClasses, knockback)}>
                    {guessData.knockbackString}&nbsp;{knockbackText}
                  </td>
                  <td className={cn(tableClasses, useTime)}>
                    {guessData.useTimeString} {useTimeText}
                  </td>
                  <td
                    className={cn(
                      tableClasses,
                      guessData.rarity == weapon.rarity ? bgCorrect : bgWrong,
                    )}
                  >
                    <span
                      className={cn(
                        "rarity",
                        toCamelCase(guessData.rarity) + "Rarity",
                      )}
                    >
                      {guessData.rarity}
                    </span>{" "}
                    {rarityText}
                  </td>
                  <td
                    className={cn(
                      tableClasses,
                      guessData.obtained == weapon.obtained
                        ? bgCorrect
                        : bgWrong,
                    )}
                  >
                    {guessData.obtained}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="text-center">
          <p>
            Report bugs to <span className="underline">sk33r7</span> on discord
          </p>
          <Copyright />
        </div>
      </div>
    </main>
  );
}

export default App;
