import {
  Knockback,
  UseTime,
  KnockbackGuess,
  UseTimeGuess,
  Rarity,
} from "../generator/types";

interface compareOut {
  matching: boolean;
  u: boolean;
}

export function toCamelCase(input: string): string {
  // Split the input string into words
  const words = input.split(/[\s-_]+/);

  // Capitalize the first letter of each word except the first one
  const camelCasedWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });

  // Join the words to form camelCase
  const camelCaseString = camelCasedWords.join("");

  return camelCaseString;
}

export function RarityToInt(rarity: Rarity): number {
  switch (rarity) {
    case "White":
      return 0;
    case "Blue":
      return 1;
    case "Green":
      return 2;
    case "Orange":
      return 3;
    case "Light Red":
      return 4;
    case "Pink":
      return 5;
    case "Light Purple":
      return 6;
    case "Lime":
      return 7;
    case "Yellow":
      return 8;
    case "Cyan":
      return 9;
    case "Red":
      return 10;
    case "Purple":
      return 11;
    case "Turquoise":
      return 12;
    case "Pure Green":
      return 13;
    case "Dark Blue":
      return 14;
    case "Violet":
      return 15;
    case "Hot Pink":
      return 16;
    case "Calamity Red":
      return 17;
    default:
      return 0;
  }
}

export function compareKnockback(
  guess: Knockback,
  base: Knockback,
): compareOut {
  if (guess == base) {
    return { matching: true, u: false };
  } else if (KnockbackGuess[guess] < KnockbackGuess[base]) {
    return { matching: false, u: true };
  } else {
    return { matching: false, u: false };
  }
}

export function compareUseTime(guess: UseTime, base: UseTime): compareOut {
  if (guess == base) {
    return { matching: true, u: false };
  } else if (UseTimeGuess[guess] > UseTimeGuess[base]) {
    return { matching: false, u: true };
  } else {
    return { matching: false, u: false };
  }
}
